<template>
    <div>
        <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch" v-if="isSearch">
            <le-input-district-choose label="省市区" 
                :province.sync="pageParam.params.provinceCode" 
                :city.sync="pageParam.params.cityCode" 
                :area.sync="pageParam.params.areaCode"></le-input-district-choose>
            <le-company-under-select label="商户" v-model="pageParam.params.companyId"></le-company-under-select>
            <le-select-remote-search label="项目" v-model="pageParam.params.incomeId" :options="optionsincomeDistri" placeholder="请选择（可输入搜索）" />
            <le-select-remote-search label="站点" reserveKeyword selectAll multiple collapse v-model="pageParam.params.stationIdList" :options="optionsStation" placeholder="请选择（可输入搜索）" />
            <le-date-range ref="dateRange" label="时间"  :minDate.sync="pageParam.params.startDay" :maxDate.sync="pageParam.params.endDay" :clearable="false" />
            <le-select-remote-search label="站点标签" v-model="pageParam.params.tagId" :options="optionsStationlabel" placeholder="请选择标签(可搜索)" />
        </le-search-form>
        <le-pagview 
            ref="businessAnalysisStation" 
            @setData="setTableData" 
            :pageParam="pageParam" 
            :isFixed="isFixed" 
            :tableLoading="tableLoading"
            :pageSizeDefault='10'
            :tableRef="isFixed?$refs.businessAnalysisStationList:null">
            <el-table ref="businessAnalysisStationList" 
                :data="tableData" 
                :highlight-current-row="true" 
                v-sticky="isSticky?{ top: 0, parent:'.el-card__body' }:{ top: 0, parent:null }" 
                :max-height="!isFixed?500:null"
                style="width: 100%">
                <el-table-column prop="stationName" label="站点名称" min-width="274">
                    <template slot-scope="{ row }">
                        <span class="a-c-blue font-point" @click="toBusinessAnalysisInfo(row)">{{ row.stationName?row.stationName:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="stationName" label="总营业额" min-width="140">
                    <template slot-scope="{ row }">
                        <div class="a-flex-cfsfs a-w-100" style="align-items: flex-start !important;">
                            <span>总营收：￥{{ row.allTransactionAmount?(row.allTransactionAmount/100).toFixed(2):0 }}</span>
                            <span>退款：￥{{ row.allRefundAmount?(row.allRefundAmount/100).toFixed(2):0 }}</span>
                            <span>实收：￥{{ util.numFormat(row.allTransactionAmount - row.allRefundAmount) }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="companyName" label="累计收益(元)" min-width="180">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs" style="align-items: flex-start !important">
                                <span>总收益：￥{{ row.allIncomeAmount?(row.allIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>套餐收益：￥{{ row.allMonthCardIncomeAmount?(row.allMonthCardIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>充电桩收益：￥{{ row.allChargingIncomeAmount?(row.allChargingIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>充电柜收益：￥{{ row.allBoxIncomeAmount?(row.allBoxIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>充电舱收益：￥{{ util.numFormat(row.allRoomIncomeAmount) }}</span>
                                <span>毛豆消耗：{{ (row.allPoints || 0) - (row.allPointsRefund || 0) }}</span>
                            </div>
                        </template>
                    </el-table-column>
                <el-table-column prop="stationName" label="实际营收" min-width="220">
                    <template slot-scope="{ row }">
                        <div class="a-w-100 a-flex-cfsfs">
                            <div v-for="(item,index) in row.companyStationInfoList" :key="index" class="sjys a-flex-rfsc a-w-100">
                                <el-tooltip class="item" effect="dark" :content="item.companyName" placement="top">
                                    <span class="companyNoComplete textOver1">{{ item.companyName?item.companyName:'-' }}</span>
                                </el-tooltip>
                                <span class="a-c-333 a-fw-700">：￥{{ item.incomePrice?(item.incomePrice/100).toFixed(2):0 }}</span>
                            </div>
                            <div class="a-flex-rfsc sjys a-w-100">
                                <span v-if="!row.companyStationInfoList || !row.companyStationInfoList.length">-</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="companyName" label="搜索时间范围内营业额" min-width="180">
                    <template slot-scope="{ row }">
                        <div class="a-flex-cfsfs a-w-100" style="align-items: flex-start !important;">
                            <span>总营收：￥{{ row.timeTransaction?(row.timeTransaction/100).toFixed(2):0 }}</span>
                            <span>退款：￥{{ row.timeRefundAmount?(row.timeRefundAmount/100).toFixed(2):0 }}</span>
                            <span>实收：￥{{ util.numFormat(row.timeTransaction - row.timeRefundAmount) }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="companyName" label="搜索时间范围内收益" min-width="180">
                    <template slot-scope="{ row }">
                        <div class="a-flex-cfsfs" style="align-items: flex-start !important">
                            <span>总收益：￥{{ row.timeIncomeAmount?(row.timeIncomeAmount/100).toFixed(2):0 }}</span>
                            <span>套餐收益：￥{{ row.monthCardIncomeAmount?(row.monthCardIncomeAmount/100).toFixed(2):0 }}</span>
                            <span>充电桩收益：￥{{ row.chargingIncomeAmount?(row.chargingIncomeAmount/100).toFixed(2):0 }}</span>
                            <span>充电柜收益：￥{{ row.boxIncomeAmount?(row.boxIncomeAmount/100).toFixed(2):0 }}</span>
                            <span>充电舱收益：￥{{ util.numFormat(row.roomIncomeAmount) }}</span>
                            <span>毛豆消耗：{{ (row.points || 0) - (row.pointsRefund || 0) }}</span>
                            <!-- <span>水机收益：￥{{ row.waterIncomeAmount?(row.waterIncomeAmount/100).toFixed(2):0 }}</span> -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="历史单插槽收益" min-width="120">
                    <template slot-scope="{ row }">
                        <span>￥{{ row.hisPerDeviceAmount?(row.hisPerDeviceAmount/100).toFixed(2):0 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="onlineStatus" label="搜索时间范围内单插槽收益" min-width="180">
                    <template slot-scope="{ row }">
                        <span>￥{{ row.timePerDeviceAmount?(row.timePerDeviceAmount/100).toFixed(2):0 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="deviceType" label="单插槽使用率" min-width="152">
                    <template slot-scope="{ row }">
                        <span>{{ row.slotUsingRationHis || 0 }}%</span>
                    </template>
                </el-table-column>
                <el-table-column prop="deviceType" label="单插槽使用率" min-width="152">
                    <template slot="header">
                        <div class="a-flex-cfsfs">
                            <span>单插槽使用率</span>
                            <span class="a-fs-12 a-c-999">搜索时间内</span>
                        </div>
                    </template>
                    <template slot-scope="{ row }">
                        <span>{{ row.slotUsingRationTime || 0 }}%</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="设备（在线数/总数）" min-width="160">
                    <template slot-scope="{ row }">
                        <span class="a-c-green">{{ row.onlineNum?row.onlineNum:0 }}</span>
                        <span>/{{ row.deviceNum?row.deviceNum:0 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="正在充电插槽数" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.useNum?row.useNum:0 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="操作" width="70" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="详情" placement="top">
                            <img src="../../../assets/icon/option-detail.png" class="a-wh-16" @click="toBusinessAnalysisInfo(scope.row)" />
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <template slot="footerLabel" slot-scope="scope" >
                <div class="a-flex-rfsc" v-if="scope.val != -1">
                    <span class="a-fs-12 a-c-normal">筛选出</span>
                    <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val}}</span>
                    <span class="a-fs-12 a-c-normal">条结果</span>
                </div>
            </template>
        </le-pagview>
    </div>
</template>

<script>
import util from '../../../../src/utils/util'
export default {
    data() {
        return {
            util: util,
            tableData: [],
            optionsincomeDistri: {
                url: this.$Config.apiUrl.incomeDistriList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
            },//项目数据
            optionsStation: {
                url: this.$Config.apiUrl.getStationInfoList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "searchKey",
            },//站点数据
            optionsStationlabel: {
                url: this.$Config.apiUrl.getStationTagList,
                method: "post",
                params: {
                    searchKey: "",
                },
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
            },
        };
    },
    props: {
        isFixed: {
            default: true,
        },
        isSticky:{
            default: true
        },
        isSearch: {
            default: true
        },
        pageParam: {
            default: {}
        },
        tableLoading: {
            default: false
        }
    },
    mounted() {
        
    },
    watch:{
        pageParam:{
            deep:true,
            handler (val) {
                this.$emit('update:pageParam',val)
            }
        }
    },
    methods: {
        //获取设备列表
        setTableData(data,fileUrl) {
            this.tableData = data;
        },
        handlerRest() {
            this.pageParam.params = {
                companyId: '',
                incomeId: '',
                stationId: '',
                provenceCode: '',
                cityCode: '',
                areaCode: '',
                stationIdList: [],
                startDay: this.$getDay.getTodayBeforeDays(30),
                endDay: this.$getDay.getTodayBeforeDays(0),
                tagId: ''
            };
            this.$refs['dateRange'].value1 = [this.$getDay.getTodayBeforeDays(30),this.$getDay.getTodayBeforeDays(0)]
            this.handlerSearch()
        },
        handlerSearch() {
            this.$refs['businessAnalysisStation'].pageNum = 1
            this.pageParam.freshCtrl++;
        },
        toBusinessAnalysisInfo (datas) {
            if(this.isFixed){
                this.$router.push({
                    path: '/businessAnalysis/businessAnalysis-info',
                    query: {
                        id: datas.stationId,
                        title: datas.stationName,
                        type: 'station',
                        stationType: datas.stationType,
                    }
                })
            }else{
                this.$eventBus.$emit('incomeToStationInfo',{
                    id: datas.stationId,
                    title: datas.stationName,
                    type: 'station',
                })
                this.$nextTick(()=>{
                    if(document.getElementsByClassName('el-card__body') && document.getElementsByClassName('el-card__body')[0]) {
                        document.getElementsByClassName('el-card__body')[0].scrollTop = 0

                    }
                })
            }
            
        },
        selectQueryStation () {

        }
    },
};
</script>

<style lang="scss" scoped>
.companyNoComplete{
    max-width: 120px;
}
</style>